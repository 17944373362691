<template>
  <div class="shade">
    <div class="top-back">
      <NavBar
        :bool="true"
        :iconColor="iconColor"
        :style="{ 'background-color': backColor }"
        @navCallback="$router.push('/my')"
      ></NavBar>
      <div class="hint-info">
        <div class="confirm-img">
          <img src="~@/assets/common/confirm.png" />
        </div>
        <h3>评价成功</h3>
      </div>
      <div class="button-box">
        <div class="button" @click="goToUrl(1, 0)">
          <van-button plain round block>返回首页</van-button>
        </div>
        <div class="button" @click="goToUrl(2, 0)">
          <van-button plain round block>查看评价</van-button>
        </div>
      </div>
    </div>
    <div class="page-layout">
      <div class="content-card" v-if="list.length > 0">
        <div class="shop-title-left">这些宝贝还没有评价哦</div>
        <!-- 商品信息 -->
        <div
          class="goods-info"
          v-for="(c_info, c_index) in list"
          :key="c_index"
        >
          <div class="goods-images-box">
            <div class="buy-details">
              <div class="goods-img-box">
                <img :src="c_info.goods_img" class="goods-img" />
              </div>
              <div class="sku-info">{{ c_info.goods_name }}</div>
            </div>
          </div>
          <div class="comments-button">
            <!-- goPublishEvaluate -->
            <van-button
              type="danger"
              plain
              round
              @click="goToUrl(3, c_info.id)"
              class="button-comments"
            >
              写评价
            </van-button>
          </div>
        </div>
      </div>
      <div class="detailsTitle">-为你推荐-</div>
      <GoodsShowLabel :tagId="0" :sendType="3"> </GoodsShowLabel>
    </div>
  </div>
</template>

<script>
import NavBar from "@/layout/nav-bar/nav-bar";
import GoodsShowLabel from "@/components/goods-show/goods-show-label";
import { getRemainList } from "@/api/goods";
export default {
  name: "publish-succeed",
  components: {
    NavBar,
    GoodsShowLabel,
  },
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      backColor: "",
      iconColor: "#fff",
    };
  },
  mounted() {
    window.addEventListener("scroll", this.windowScroll); //监听页面滚动
    this.getRemainList();
  },
  methods: {
    async getRemainList() {
      const res = await getRemainList();
      if (res.code * 1 === 1) {
        ++this.page;
        this.list = this.list.concat(res.data.items);
        this.total = res.data.total;
      }
    },
    goToUrl(type, data) {
      //页面跳转
      switch (type) {
        case 1:
          //订单详情
          this.$router.push({ name: "home" });
          break;
        case 2:
          //店铺详情
          this.$router.push({ name: "my-evaluate" });
          break;
        case 3:
          this.$router.push({
            name: "publish-evaluate",
            query: { itemId: data },
          });
          break;
        case 4:
          break;
        default:
      }
    },
    windowScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 140) {
        this.backColor = "#fff";
        this.iconColor = "#9B9B9B";
      } else {
        this.backColor = "";
        this.iconColor = "#fff";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.shade {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  background-color: #f2f2f2;
}
.top-back {
  height: 182px;
  background: linear-gradient(to right, #f9a075, #ff3e46);
  .hint-info {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 48px;
    .confirm-img {
      width: 28px;
      margin-right: 10px;
      img {
        display: block;
        width: 100%;
      }
    }
    h3 {
      color: #fff;
      font-size: 21px;
      font-weight: 500;
    }
  }

  .button-box {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
    padding: 0 60px;
    .van-button__text {
      font-size: 16px;
    }
    .van-button--normal {
      padding: 0 28px;
    }
    .van-button--plain {
      background-color: transparent;
    }
    .van-button--default {
      color: #f8f7f7;
    }
    .van-button--default {
      border: 1px solid #f8f7f7;
    }
  }
}
// 订单卡片样式
.content-card {
  border-radius: 16px;
  padding: 12px;
  background-color: #fff;
  .shop-title-left {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    .title-text {
      margin: 0 4px;
      font-weight: 600;
      color: #000;
    }
  }
}
// 商品信息
.goods-info {
  display: flex;
  padding: 10px 0;
  justify-content: space-between;
  align-items: flex-end;
  .goods-images-box {
    overflow-x: scroll;
  }
  .buy-details {
    display: flex;
    justify-content: center;
    .goods-img-box {
      flex-shrink: 0;
      width: 90px;
      .goods-img {
        width: 90px;
        height: 90px;
        border-radius: 6px;
        display: block;
      }
    }
    .sku-info {
      margin-left: 12px;
      font-size: 17px;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .comments-button {
    .van-button {
      height: 40px;
    }
    .van-button--normal {
      padding: 0 26px;
    }
  }
  .button-comments {
    width: 78px;
    height: 32px;
    font-size: 16px;
    padding: 0 !important;
  }
}
.detailsTitle {
  text-align: center;
  padding: 10px 0;
  font-size: 18px;
  font-weight: 500;
}
</style>
